function fixDataContents() {
    $(".data_contents p span").css("font-size", "unset"), $(".data_contents div span").css("font-size", "unset"), $(".data_contents ul li").css("list-style", "unset"), $(".data_contents span").css("font-family", "unset"), $(".data_contents iframe").wrap('<div class="wrapIframeVideo"><div class="wrapWidthVideo"></div></div>'), $(".data_contents video").wrap('<div class="wrapVideoTagProcess"></div>'), $(".data_contents img").wrap('<p style="text-align:center;"></p>'), $(".data_contents img").removeAttr("style"), $(".data_contents table").removeAttr("style")
}

function setCustomValidationMessages() {
    jQuery.extend(jQuery.validator.messages, {required: "Vui l\xf2ng nh\u1eadp th\xf4ng tin b\u1eaft bu\u1ed9c"})
}

$(document).ready((function () {
    $(".contactCallPopUp").click((function () {
        var e = $(this).attr("dataHref"), t = $(this).attr("contactText"), a = $(this).attr("idGoogleGet"),
            i = $(".popupAskContact .modal-footer .btnContact");
        i.attr("href", e), i.attr("id", a), i.text(t)
    }))
})), $(document).ready((function () {
    function e(e) {
        return '<div class="specialVideoBlock_1" datasrcvideo="https://www.youtube.com/embed/' + e + '?autoplay=1"><div class="wrapVideo"><img alt="fagopet" class="imgVideo" loading="lazy" src="https://i.ytimg.com/vi/' + e + '/maxresdefault.jpg"/></div><div class="wrapIconPlay"><i aria-hidden="true" class="fa fa-play"></i></div></div>'
    }

    function t() {
        function e() {
            var e = $("#menuEndPage").innerHeight();
            o <= 767 ? $("#footerKHM").css("margin-bottom", e) : $("#footerKHM").css("margin-bottom", "0")
        }

        e()
    }

    function a() {
        $("#menuKHM_MOBILE .searchArea .searchClickItem .fa-search").click((function () {
            $(this).fadeOut(0), $(this).parent().find(".fa-times").fadeIn(200), $(this).parent().parent().find(".searchDropDown").fadeIn(300)
        })), $("#menuKHM_MOBILE .searchArea .searchClickItem .fa-times").click((function () {
            $(this).fadeOut(0), $(this).parent().find(".fa-search").fadeIn(200), $(this).parent().parent().find(".searchDropDown").fadeOut(300)
        })), $("#menuKHM_MOBILE .barsArea .barsPart").click((function () {
            $(this).hasClass("active") ? ($(this).removeClass("active"), $("#blockCateMobile").removeClass("active")) : ($(this).addClass("active"), $("#blockCateMobile").addClass("active"))
        })), $("#blockCateMobile .blockCateLevel_1 .menuItem_1 .btnToggle_1").click((function () {
            var e = $(this).parent(), t = $(this).parent().parent();
            e.hasClass("active") ? e.removeClass("active") : (t.find(".menuItem_1").removeClass("active"), e.addClass("active"))
        })), $("#blockCateMobile .blockCateLevel_2 .menuItem_2 .btnToggle_2").click((function () {
            var e = $(this).parent().attr("connectCate2And3");
            $("#blockCateMobile .blockCateLevel_3").addClass("active"), $("#blockCateMobile .blockCateLevel_3").find(".wrapBlockCate_3").removeClass("active"), $("#blockCateMobile .blockCateLevel_3").find('.wrapBlockCate_3[connectCate2And3="' + e + '"]').addClass("active")
        })), $("#blockCateMobile .blockCateLevel_3 .btnBackToBlockCate_2").click((function () {
            $("#blockCateMobile .blockCateLevel_3").removeClass("active")
        })), $("#blockCateMobile .blockCateLevel_3 .menuItem_3 .btnToggle_3").click((function () {
            var e = $(this).parent(), t = $(this).parent().parent();
            e.hasClass("active") ? (e.removeClass("active"), e.find(".blockCateLevel_4").removeClass("active")) : (t.find(".menuItem_3").removeClass("active"), e.addClass("active"), e.find(".blockCateLevel_4").addClass("active"))
        }))
    }

    var o = $(window).width();
    $(".clickGoLoading").click((function () {
        widthBtn = $(this).outerWidth(), $(this).css("display", "none"), $(this).parent().find(".buttonload").css("width", widthBtn), $(this).parent().find(".buttonload").css("display", "inline-block"), setTimeout((function () {
            $(this).fadeIn(300), $(this).parent().find(".buttonload").css("display", "none")
        }), 3e3)
    })), $(".owlMagazineBlock").owlCarousel({
        loop: !0,
        margin: 0,
        nav: !0,
        dots: !1,
        animateOut: "fadeOut",
        autoplay: !0,
        autoplayTimeout: 3e3,
        autoplayHoverPause: !0,
        smartSpeed: 1e3,
        responsive: {0: {items: 1, stagePadding: 40}, 767: {items: 2}, 991: {items: 3}}
    }), $(".owlMagazineBlock .owl-nav").attr("id", "owl-nav1"), $(".owlMagazineBlock .owl-dots").attr("id", "owl-dot1"), setTimeout((function () {
        $("#sidebarBlock").length && $("#sidebarBlock").stickySidebar({
            topSpacing: 80,
            bottomSpacing: 10,
            containerSelector: !1,
            innerWrapperSelector: ".sidebar__inner",
            resizeSensor: !0,
            stickyClass: "is-affixed",
            minWidth: 992
        })
    }), 2e3), $(".wrapSticky").length && $((function () {
        var e = $(window), t = e.scrollTop(), a = $(".wrapSticky");
        if (a.length > 0) {
            var i = a.position().top;
            e.scroll((function () {
                var o = e.height(), n = a.outerHeight(), r = e.scrollTop(), l = r + o, s = a.position().top, c = s + n,
                    d = Math.abs(o - n), u = t - r, p = r > t, m = o > n;
                m && r > i || !m && r > i + d ? a.addClass("fixed") : !p && r <= i && a.removeClass("fixed");
                var f = s >= r && !p;
                if (c <= l && p) m ? a.css("top", 0) : a.css("top", -d); else if (f) a.css("top", 0); else if (a.hasClass("fixed")) {
                    var v = -d, h = parseInt(a.css("top"), 10) + u, k = r + o >= $(document).height() ? v : h;
                    a.css("top", k)
                }
                t = r, p
            }))
        }
    }));
    var n = document.querySelectorAll(".processVideoYoutube");
    if (n.length > 0) for (i = 0; i < n.length; i++) {
        var r = e(n[i].innerHTML);
        n[i].innerHTML = r
    }
    if ($(".specialVideoBlock_1").click((function () {
        var e = $(this).attr("dataSrcVideo");
        $(this).find(".wrapVideo").addClass("active"), $(this).find(".wrapVideo").append('<img class="imgLoad" src="/uploads/images/img_loading_1.gif" alt="loading"><iframe width="560" height="315" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>'), $(this).find(".wrapVideo iframe").attr("src", e), $(this).find(".wrapIconPlay").fadeOut(0)
    })), $(".specialBlock_43").length && Fancybox.bind("[data-fancybox]", {infinite: !1}), $(".contactCallPopUp").click((function () {
        var e = $(this).attr("dataHref"), t = $(this).attr("contactText"), a = $(this).attr("idGoogleGet"),
            i = $(".popupAskContact .modal-footer .btnContact");
        i.attr("href", e), i.attr("id", a), i.text(t)
    })), $(".specialBlock_1 .slide").owlCarousel({
        loop: !0,
        margin: 0,
        nav: !0,
        dots: !1,
        mouseDrag: !1,
        autoplay: !0,
        autoplayTimeout: 5e3,
        autoplayHoverPause: !1,
        smartSpeed: 500,
        items: 1
    }), $(".specialBlock_1 .slide .owl-nav").attr("id", "owl-nav2"), $(".specialBlock_4 .slide").owlCarousel({
        loop: !0,
        smartSpeed: 500,
        responsive: {
            0: {margin: 5, nav: !1, dots: !0, items: 2},
            767: {margin: 0, nav: !0, dots: !1, items: 3},
            991: {margin: 0, nav: !0, dots: !1, items: 4}
        }
    }), $(".specialBlock_4 .slide .owl-nav").attr("id", "owl-nav2"), $(".specialBlock_4 .slide .owl-dots").attr("id", "owl-dot2"), $(".specialBlock_6 .aTag1").click((function () {
        $(this).css("display", "none"), $(this).parent().find(".aTag2").fadeIn(600)
    })), $(".specialBlock_9 .slide").owlCarousel({
        loop: !1,
        nav: !1,
        dots: !0,
        margin: 30,
        mouseDrag: !1,
        animateOut: "fadeOut",
        autoplayTimeout: 2e3,
        autoplayHoverPause: !0,
        smartSpeed: 1e3,
        items: 1
    }), $(".specialBlock_9 .slide .owl-dots").attr("id", "owl-dot1"), $(".specialBlock_14 .slide").owlCarousel({
        loop: !0,
        margin: 0,
        nav: !0,
        dots: !1,
        smartSpeed: 400,
        responsive: {0: {items: 1}, 575: {items: 2}, 991: {items: 3}}
    }), $(".specialBlock_14 .slide .owl-nav").attr("id", "owl-nav2"), $(".specialBlock_27").length) {
        var l = $(".specialBlock_27 .bigImgBlock"), s = $(".specialBlock_27 .smallImgBlock"), c = 4, d = !0;

        function u(e) {
            var t = e.item.count - 1, a = Math.round(e.item.index - e.item.count / 2 - .5);
            a < 0 && (a = t), a > t && (a = 0), s.find(".owl-item").removeClass("current").eq(a).addClass("current");
            var i = s.find(".owl-item.active").length - 1, o = s.find(".owl-item.active").first().index();
            a > s.find(".owl-item.active").last().index() && s.data("owl.carousel").to(a, 100, !0), a < o && s.data("owl.carousel").to(a - i, 100, !0)
        }

        function p(e) {
            if (d) {
                var t = e.item.index;
                l.data("owl.carousel").to(t, 100, !0)
            }
        }

        l.owlCarousel({
            items: 1,
            slideSpeed: 2e3,
            nav: !1,
            autoplay: !1,
            dots: !1,
            loop: !0,
            margin: 5,
            responsiveRefreshRate: 200
        }).on("changed.owl.carousel", u), s.on("initialized.owl.carousel", (function () {
            s.find(".owl-item").eq(0).addClass("current")
        })).owlCarousel({
            dots: !1,
            nav: !0,
            smartSpeed: 200,
            slideSpeed: 500,
            margin: 10,
            mouseDrag: !1,
            slideBy: c,
            responsiveRefreshRate: 100,
            responsive: {0: {items: 3}, 575: {items: c}}
        }).on("changed.owl.carousel", p), s.on("click", ".owl-item", (function (e) {
            e.preventDefault();
            var t = $(this).index();
            l.data("owl.carousel").to(t, 300, !0)
        })), s.find(".owl-nav").attr("id", "owl-nav2")
    }
    $(".specialBlock_29 .aTag1").click((function () {
        $(this).css("display", "none"), $(this).parent().find(".aTag2").fadeIn(600)
    })), $(".specialBlock_34 .slide").owlCarousel({
        loop: !0,
        margin: 20,
        nav: !0,
        dots: !1,
        smartSpeed: 500,
        responsive: {0: {items: 1}, 767: {items: 2}, 991: {items: 3}}
    }), $(".specialBlock_34 .slide .owl-nav").attr("id", "owl-nav2"), $(".specialBlock_43").each((function () {
        var e = $(this).find(".fancybox").length;
        $(this).find(".numOfImg").text(e)
    })), $(".specialBlock_44 .slide").owlCarousel({
        loop: !1,
        nav: !0,
        dots: !1,
        smartSpeed: 500,
        responsive: {0: {items: 1, margin: 5}, 575: {items: 2, margin: 10}, 767: {items: 3, margin: 15}}
    }), $(".specialBlock_44 .slide .owl-nav").attr("id", "owl-nav2"), $(window).scroll((function () {
        $("html,body").scrollTop() > 100 ? $("#pageUpBlock .pageUp").removeClass("hidePageUp") : $("#pageUpBlock .pageUp").addClass("hidePageUp")
    })), $("#pageUpBlock .pageUp").click((function () {
        $("html,body").animate({scrollTop: 0}, 600)
    })), t(), a(), $("#owl-nav1 .owl-prev span").replaceWith("\u2039"), $("#owl-nav1 .owl-next span").replaceWith("\u203a"), $("#owl-nav2 .owl-prev span").replaceWith("\u2039"), $("#owl-nav2 .owl-next span").replaceWith("\u203a")
})), $(document).ready((function () {
    function e() {
        var e = $(this).find("img").attr("datalinkvideo"), t = $(this).parent().find(".videoFloatBlock").clone();
        t.find(".videoPart iframe").attr("src", e), t.css("display", "block"), $("body").prepend(t), $(".videoFloatBlock").click((function () {
            t.remove()
        })), $(".videoFloatBlock .btnHideVideo .fa-times").click((function () {
            t.remove()
        }))
    }

    $(".owlBlockVideos").owlCarousel({
        loop: !0,
        margin: 10,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 1}}
    }), $(".secVideo .blockVideos .wrapVideo").click((function () {
        $(".secVideo .blockVideos .wrapVideo").removeClass("active"), $(this).addClass("active");
        var e = $(this).find(".detailVideoBlock .textPart .titleVideo").text(),
            t = $(this).find(".detailVideoBlock .textPart .numberView").text(),
            a = $(this).find(".detailVideoBlock .textPart .timePost").text(),
            i = $(this).find(".detailVideoBlock .videoPart iframe").attr("src");
        $(".secVideo .leftPart .wrapBigVideo .videoPart iframe").attr("src", i), $(".secVideo .leftPart .wrapBigVideo .titleVideo").text(e), $(".secVideo .leftPart .wrapBigVideo .numberView").text(t), $(".secVideo .leftPart .wrapBigVideo .timePost").text(a)
    })), $(".owlBlockVideos .owl-nav").attr("id", "owl-nav2"), $(".owlSmallMedia").owlCarousel({
        loop: !1,
        margin: 10,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 3}, 991: {items: 4}}
    }), $(".mediaMulti .bigMediaShow .mediaPart:eq(0)").show(), $(".owlSmallMedia .mediaPart").click((function () {
        $(".owlSmallMedia .mediaPart").removeClass("active"), $(this).addClass("active");
        var e = $(this).attr("nameMedia");
        $(".mediaMulti .bigMediaShow .mediaPart").hide(), $('.mediaMulti .bigMediaShow .mediaPart[nameMedia="' + e + '"]').fadeIn(300)
    })), $(".owlSmallMedia .owl-nav").attr("id", "owl-nav1"), $(".owlBlockVideo1").owlCarousel({
        loop: !1,
        margin: 20,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 1}, 575: {items: 2}, 991: {items: 3}}
    }), $(".owlBlockVideo1 .owl-nav").attr("id", "owl-nav1"), $(".owlSubcate1").owlCarousel({
        loop: !1,
        margin: 5,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 2}, 767: {items: 3}, 991: {items: 6}}
    }), $(".owlSubcate1_2").owlCarousel({
        loop: !1,
        margin: 5,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 3}, 767: {items: 5}, 991: {items: 7}}
    }), $(".owlSubcateService").owlCarousel({
        loop: !1,
        nav: !1,
        dots: !1,
        responsive: {
            0: {autoWidth: !0, margin: 10, items: 1},
            575: {autoWidth: !1, margin: 0, items: 2},
            767: {autoWidth: !1, margin: 0, items: 3},
            991: {autoWidth: !1, margin: 0, items: 4}
        }
    }), $(".owlSubcatePolicy").owlCarousel({
        loop: !1,
        nav: !1,
        dots: !1,
        responsive: {
            0: {autoWidth: !0, margin: 15, items: 1},
            575: {autoWidth: !1, margin: 0, items: 2},
            767: {autoWidth: !1, margin: 0, items: 3},
            991: {autoWidth: !1, margin: 0, items: 6}
        }
    }), $(".blockProducts1").owlCarousel({
        loop: !1,
        margin: 15,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 2}, 767: {items: 3}, 991: {items: 4}}
    }), $(".blockProducts1 .owl-nav").attr("id", "owl-nav1"), $(".owlMyCustom").owlCarousel({
        loop: !0,
        margin: 15,
        nav: !0,
        dots: !1,
        autoplay: !0,
        autoplayTimeout: 4e3,
        autoplayHoverPause: !0,
        smartSpeed: 1e3,
        responsive: {0: {items: 3, slideBy: 1}, 767: {items: 4, slideBy: 2}, 991: {items: 5, slideBy: 2}}
    }), $(".owlMyCustom .owl-nav").attr("id", "owl-nav3"), $(".blockNews1").owlCarousel({
        loop: !1,
        margin: 20,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 1}, 575: {items: 2}, 991: {items: 3}}
    }), $(".blockNews1 .owl-nav").attr("id", "owl-nav1"), $(".blockQuestion .QandAPart .question").click((function () {
        $(this).parent().find(".answer").fadeToggle(600);
        var e = $(this).parent().find("i.fa-caret-right");
        "transform: rotate(0deg);" == e.attr("style") ? e.attr("style", "transform: rotate(90deg)") : e.attr("style", "transform: rotate(0deg);")
    })), $(".sentQuestionPart .buttonToggle").click((function () {
        $(this).parent().find(".blockFillQuestion").fadeToggle(600)
    })), $(".detailProductBlock1 .btnPart .btnFix1").click((function () {
        $(this).css("display", "none"), $(this).parent().find(".btnFix2").css("display", "inline-block")
    })), $(".sortBlock1 .owlSortBlock1").owlCarousel({
        margin: 10,
        loop: !1,
        nav: !1,
        dots: !1,
        autoWidth: !0,
        items: 2
    }), $(".sortBlock2 .devideRow .rightPart .fa-angle-down").click((function () {
        $(this).parent().parent().find(".leftPart").fadeToggle(600);
        var e = $(this);
        "transform: rotate(0deg);" == e.attr("style") ? e.attr("style", "transform: rotate(180deg)") : e.attr("style", "transform: rotate(0deg);")
    })), $(".owl_blockVideo").owlCarousel({
        loop: !1,
        margin: 20,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 1}, 575: {items: 2}, 991: {items: 3}}
    }), $(".owl_blockVideo .owl-nav").attr("id", "owl-nav1"), $(".specialVideoBlock .imgPart").click(e), $(window).scroll((function () {
        $("html,body").scrollTop() > 40 ? ($("#menuAcQuyDesk .wrapMenu2Menu3").addClass("coDinhMenu"), $("#menuAcQuyDesk .menuLine1").addClass("addMarBot")) : ($("#menuAcQuyDesk .wrapMenu2Menu3").removeClass("coDinhMenu"), $("#menuAcQuyDesk .menuLine1").removeClass("addMarBot"))
    })), $("#menuAcQuyDesk .menuLine3 .categoryPart").mouseover((function () {
        $("#menuAcQuyDesk .menuLine3 .categoryPart>.subCatePart1").addClass("appeared")
    })), $("#menuAcQuyDesk .menuLine3 .categoryPart").mouseout((function () {
        $("#menuAcQuyDesk .menuLine3 .categoryPart>.subCatePart1").removeClass("appeared")
    })), $("#menuAcQuyDesk .menuLine3 .categoryPart .subCatePart1 ul li.liDropDown").mouseover((function () {
        $(this).find(".subCatePart2").addClass("appeared")
    })), $("#menuAcQuyDesk .menuLine3 .categoryPart .subCatePart1 ul li.liDropDown").mouseout((function () {
        $(this).find(".subCatePart2").removeClass("appeared")
    })), $("#menuAcQuyDesk .menuLine3 .menuPart .dropDown").mouseover((function () {
        $(this).find(".blockLevel2").addClass("appear")
    })), $("#menuAcQuyDesk .menuLine3 .menuPart .dropDown").mouseout((function () {
        $(this).find(".blockLevel2").removeClass("appear")
    })), $("#btnContactDesk .wrapAllPart .upItemLi a").click((function () {
        $("html,body").animate({scrollTop: 0}, 600)
    })), $(window).scroll((function () {
        $("html,body").scrollTop() > 40 ? ($("#menuAcQuyMobile .menuLine2").addClass("coDinhMenu"), $("#menuAcQuyMobile .menuLine1").addClass("addMarBot")) : ($("#menuAcQuyMobile .menuLine2").removeClass("coDinhMenu"), $("#menuAcQuyMobile .menuLine1").removeClass("addMarBot"))
    })), $collapseMobileCate = 0, $("#menuAcQuyMobile .menuLine2 .barsPart").click((function () {
        0 == $collapseMobileCate ? ($("#menuAcQuyMobile .menuLine2 .blockLevel2").fadeIn(600), $collapseMobileCate = 1) : ($("#menuAcQuyMobile .menuLine2 .blockLevel2").fadeOut(600), $collapseMobileCate = 0)
    })), $("#menuAcQuyMobile .menuLine2 .blockLevel2 .catePart.dropMore .faFix.fa-angle-right").click((function () {
        $(this).parent().find(".blockLevel3").fadeIn(600), $(this).css("display", "none"), $(this).parent().find(".fa-angle-down").css("display", "block")
    })), $("#menuAcQuyMobile .menuLine2 .blockLevel2 .catePart.dropMore .faFix.fa-angle-down").click((function () {
        $(this).parent().find(".blockLevel3").fadeOut(600), $(this).css("display", "none"), $(this).parent().find(".fa-angle-right").css("display", "block")
    })), $("#menuAcQuyMobile .menuLine2 .searchPart").click((function () {
        $("#menuAcQuyMobile .menuLine2 .searchCollapse").addClass("appeared")
    })), $("#menuAcQuyMobile .menuLine2 .searchCollapse a.XItem").click((function () {
        $("#menuAcQuyMobile .menuLine2 .searchCollapse").removeClass("appeared")
    })), $("#homeAcQuy .owlSecBanner").owlCarousel({
        loop: !0,
        margin: 0,
        nav: !0,
        dots: !1,
        autoplay: !0,
        autoplayTimeout: 3500,
        animateOut: "fadeOut",
        responsive: {0: {items: 1}}
    }), $(".owlSecBanner .owl-nav").attr("id", "owl-nav4"), $("#homeAcQuy .owlBanner").owlCarousel({
        loop: !0,
        margin: 20,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 1}, 767: {items: 2}}
    }), $("#homeAcQuy .owlBanner .owl-nav").attr("id", "owl-nav4"), $(".blockSubcateOfCate3").owlCarousel({
        loop: !1,
        margin: 0,
        nav: !1,
        dots: !1,
        responsive: {0: {items: 3}, 575: {items: 6}}
    }), $(".owlMagazineLogos").owlCarousel({
        loop: !1,
        margin: 10,
        nav: !1,
        dots: !1,
        responsive: {0: {items: 4}, 991: {items: 5}}
    }), $(".slideTabBlock .owlSlideTabBlock").owlCarousel({
        loop: !1,
        margin: 0,
        nav: !1,
        dots: !1,
        responsive: {0: {items: 2}, 992: {items: 4}}
    }), $(".owlCusTalkAboutUs").owlCarousel({
        loop: !1,
        margin: 20,
        nav: !0,
        dots: !1,
        responsive: {0: {items: 1}, 575: {items: 2}, 991: {items: 3}}
    }), $(".owlCusTalkAboutUs .owl-nav").attr("id", "owl-nav1"), $("#owl-nav1 .owl-prev span").replaceWith('<img src="/builds/images/left-arrow.png" alt="left-arrow">'), $("#owl-nav1 .owl-next span").replaceWith('<img src="/builds/images/left-arrow.png" alt="left-arrow">'), $('#owl-nav2 span[aria-label="Previous"]').replaceWith('<p class="textPrevious">Back</p>'), $('#owl-nav2 span[aria-label="Next"]').replaceWith('<p class="textNext">Next</p>'), $("#owl-nav3 .owl-prev span").replaceWith('<img src="/builds/images/left-arrow.png" alt="left-arrow">'), $("#owl-nav3 .owl-next span").replaceWith('<img src="/builds/images/left-arrow.png" alt="left-arrow">'), $("#owl-nav4 .owl-prev span").replaceWith("<<"), $("#owl-nav4 .owl-next span").replaceWith(">>"), $(".secQuickSummaryProduct .selectTypePrBlock .selectionPart .smallPart img").click((function () {
        $(".secQuickSummaryProduct .selectTypePrBlock .selectionPart .smallPart").removeClass("active"), $(this).parent().addClass("active");
        var e = $(this).attr("options");
        $(".owlSmallMedia .mediaPart").removeClass("active"), $('.owlSmallMedia .mediaPart[options="' + e + '"]').addClass("active"), $(".mediaMulti .bigMediaShow .mediaPart").hide(), $('.mediaMulti .bigMediaShow .mediaPart[options="' + e + '"]').fadeIn(300)
    })), $(".secQuickSummaryProduct .rightTextProduct .selectOptionProductBlock .rightPart .typeText").click((function () {
        $(".secQuickSummaryProduct .rightTextProduct .selectOptionProductBlock .rightPart .typeText").removeClass("active"), $(this).addClass("active")
    }))
})), $(document).ready((function () {
    $(".option").click((function () {
        data_variant = "", variant = {}, $(".variant_options").each((function (e, t) {
            data_variant += "[" + $(t).find(".variant_name").attr("key") + "='" + $(t).find(".option.active").text() + "']", variant[$(t).find(".variant_name").attr("key")] = $(t).find(".option.active").text(), $('input[name="variants[' + $(t).find(".variant_name").attr("key") + ']"]').attr("value", $(t).find(".option.active").text())
        }));
        var e = $(".variants p" + data_variant), t = e.attr("variant_id"), a = e.attr("price");
        null != a ? ($("input#price").attr("value", a), $(".price_change").text(Intl.NumberFormat("de-DE").format(a) + "VND")) : (a = $(".price_change").attr("price"), $("input#price").attr("value", a), $(".price_change").text(a)), null != t ? ($("input#variant_id").attr("value", t), $(".add-to-cart").attr("data-variant-id", t)) : ($(".add-to-cart").removeAttr("data-variant-id"), $("input#variant_id").removeAttr("value"))
    })), $(".enterNumb .fa-minus-square").click((function () {
        var e = parseInt($(this).parent().find("input").val()), t = e;
        e > 0 && (t = e - 1), isNaN(t) && (t = 1), $(this).parent().find(".fa-minus-square ~ input").val(t).trigger("change")
    })), $(".enterNumb .fa-plus-square").click((function () {
      //  var e = parseInt($(this).parent().find("input").val()) + 1;
      //  isNaN(e) && (e = 1), $(this).parent().find(".fa-plus-square ~ input").val(e).trigger("change")
    })), $(".payBlock #selectAllDesk").click((function () {
        $(this).is(":checked") ? ($(".cartTable .line2 .form-check-input").prop("checked", !0), $(this).prop("checked", !0)) : ($(".cartTable .line2 .form-check-input").prop("checked", !1), $(this).prop("checked", !1))
    })), $(".cartTable .line2 .form-check-input").click((function () {
        $(this).is(":checked") || $(".payBlock #selectAllDesk").prop("checked", !1)
    })), $(".payBlock #selectAllMobile").click((function () {
        $(this).is(":checked") ? ($(".cartPayMobile .specialBlock_30 .form-check-input").prop("checked", !0), $(this).prop("checked", !0)) : ($(".cartPayMobile .specialBlock_30 .form-check-input").prop("checked", !1), $(this).prop("checked", !1))
    })), $(".cartPayMobile .specialBlock_30 .form-check-input").click((function () {
        $(this).is(":checked") || $(".payBlock #selectAllMobile").prop("checked", !1)
    })), $(".deliveryInforBlock .wrapForm-check #invoice").click((function () {
        $(this).is(":checked") ? ($(".deliveryInforBlock .TTXuatHD").fadeIn(600), $(".deliveryInforBlock .price_vat").fadeIn(600), $(".deliveryInforBlock .price_novat").fadeOut(600)) : ($(".deliveryInforBlock .TTXuatHD").fadeOut(600), $(".deliveryInforBlock .price_vat").fadeOut(600), $(".deliveryInforBlock .price_novat").fadeIn(600))
    })), $("#method_order").click((function () {
        "true" == $("#method_order").find("option:selected").attr("data") ? $(".deliveryInforBlock .form_address").fadeOut(600) : $(".deliveryInforBlock .form_address").fadeIn(600)
    })), $(".bank_type").click((function () {
        $(".display").fadeOut(1);
        var e = $(".bank_type").find("option:selected").attr("data");
        "bank_personal" == e ? ($(".bank_personal").fadeIn(600), $(".bank_company").fadeOut(1)) : "bank_company" == e ? ($(".bank_personal").fadeOut(1), $(".bank_company").fadeIn(600)) : ($(".bank_personal").fadeOut(600), $(".bank_company").fadeOut(600))
    })), $(".deliveryInforBlock .wrapForm-check #noInvoice").click((function () {
        $(this).is(":checked") && ($(".deliveryInforBlock .TTXuatHD").fadeOut(600), $(".deliveryInforBlock .price_vat").fadeOut(600), $(".deliveryInforBlock .price_novat").fadeIn(600))
    })), $(".paymentMethodBlock .detailPay .form-checkFix #thanhToanCKQNH").click((function () {
        $(this).is(":checked") && ($(".paymentMethodBlock .detailPay .wrapChooseAcount").fadeIn(600), $(".paymentMethodBlock .detailPay .wrapDepositInfo").fadeOut(0))
    })), $(".paymentMethodBlock .detailPay .form-checkFix #thanhToanKGH").click((function () {
        $(this).is(":checked") && ($(".paymentMethodBlock .detailPay .wrapChooseAcount").fadeOut(0), $(".paymentMethodBlock .detailPay .wrapDepositInfo").fadeIn(600))
    }))
})), $(document).ready((function () {
    $(".data_contents").length && fixDataContents()
})), $((function () {
    $("form.lien-he").each(((e, t) => {
        $(t).validate({
            onfocusout: !1,
            onkeyup: !1,
            onclick: !1,
            rules: {
                name: {required: !0},
                phone: {required: !0, phoneNumber: !0},
                email: {required: !0, email: !0},
                note: {required: !0}
            },
            messages: {
                name: "Vui l\xf2ng nh\u1eadp t\xean",
                phone: {
                    required: "Vui l\xf2ng nh\u1eadp s\u1ed1 \u0111i\u1ec7n tho\u1ea1i",
                    phoneNumber: "Vui l\xf2ng nh\u1eadp \u0111\xfang s\u1ed1 \u0111i\u1ec7n tho\u1ea1i"
                },
                email: {
                    required: "Vui l\xf2ng nh\u1eadp email",
                    email: "Vui l\xf2ng nh\u1eadp \u0111\xfang \u0111\u1ecbnh d\u1ea1ng email"
                }
            }
        })
    })), $("form.dang-ky").each(((e, t) => {
        $(t).validate({
            onfocusout: !1,
            onkeyup: !1,
            onclick: !1,
            rules: {
                name: {required: !0},
                phone: {required: !0, phoneNumber: !0},
                email: {required: !0, email: !0},
                note: {required: !0}
            },
            messages: {
                name: {required: "Vui l\xf2ng nh\u1eadp t\xean"},
                phone: {
                    required: "Vui l\xf2ng nh\u1eadp s\u1ed1 \u0111i\u1ec7n tho\u1ea1i",
                    phoneNumber: "Vui l\xf2ng nh\u1eadp \u0111\xfang s\u1ed1 \u0111i\u1ec7n tho\u1ea1i"
                },
                note: {required: "Vui l\xf2ng nh\u1eadp n\u1ed9i dung c\u1ea7n t\u01b0 v\u1ea5n"},
                email: {
                    required: "Vui l\xf2ng nh\u1eadp email",
                    email: "Vui l\xf2ng nh\u1eadp \u0111\xfang \u0111\u1ecbnh d\u1ea1ng email"
                }
            }
        })
    })), $("form.dang-ky-tu-van").each(((e, t) => {
        $(t).validate({
            onfocusout: !1,
            onkeyup: !1,
            onclick: !1,
            rules: {
                name: {required: !0},
                phone: {required: !0, phoneNumber: !0},
                email: {required: !0, email: !0},
                note: {required: !0}
            },
            messages: {
                name: {required: "Vui l\xf2ng nh\u1eadp t\xean"},
                phone: {
                    required: "Vui l\xf2ng nh\u1eadp s\u1ed1 \u0111i\u1ec7n tho\u1ea1i",
                    phoneNumber: "Vui l\xf2ng nh\u1eadp \u0111\xfang s\u1ed1 \u0111i\u1ec7n tho\u1ea1i"
                },
                note: {required: "Vui l\xf2ng nh\u1eadp n\u1ed9i dung c\u1ea7n t\u01b0 v\u1ea5n"},
                email: {
                    required: "Vui l\xf2ng nh\u1eadp email",
                    email: "Vui l\xf2ng nh\u1eadp \u0111\xfang \u0111\u1ecbnh d\u1ea1ng email"
                }
            }
        })
    }))
})), $((function () {
    $("form.order-form").each(((e, t) => {
        $(t).validate({
            onfocusout: !1,
            onkeyup: !1,
            onclick: !1,
            rules: {
                customer_name: {required: !0},
                customer_phone: {required: !0, phoneNumber: !0},
                customer_email: {required: !0, email: !0},
                "shipping_address[address]": {required: !0},
                "shipping_address[province]": {required: !0},
                "shipping_address[district]": {required: !0},
                "shipping_address[ward]": {required: !0},
                "invoice_data[company_name]": {required: !0},
                "invoice_data[tax_number]": {required: !0},
                "invoice_data[address]": {required: !0}
            },
            messages: {}
        })
    })), $("form.order-form .submit-order").click((() => {
        $("form.order-form").submit()
    })), $(".submit-direct-order.ksch").click((() => {
        const e = $("form.product-form").find(".typePet.active"), t = e.attr("typeroom"), a = e.text(),
            i = e.attr("priceroom"), o = $("form.product-form");
        o.find('input[name="variants[room_type]"]').val(t), o.find('input[name="variants[pet_type]"]').val(a), o.find('input[name="price"]').val(i), o.submit()
    })), $(".submit-direct-order.spa-cho-meo").click((() => {
        const e = $("form.product-form"), t = e.find(".serviceTab.active").text(),
            a = e.find(".serviceBlock.active").find(".typePet.active"), i = a.text(), o = a.attr("priceroom");
        e.find('input[name="variants[room_type]"]').val(t), e.find('input[name="variants[pet_type]"]').val(i), e.find('input[name="price"]').val(o), e.submit()
    })), $(".submit-direct-order.product").click((() => {
        $("form.product-form").submit()
    })), $("form.order-form").on("onValid", (function () {
        $(this).parent().find(".buttonload_booking").css("display", "block"), $(this).parent().find(".submit-order").css("display", "none")
    })), $("form.order-form").on("onSuccess", (function () {
        $(this).parent().find(".buttonload_booking").css("display", "none"), $(this).parent().find(".submit-order").css("display", "block")
    })), $("form.order-form").on("onError", (function () {
        $(this).parent().find(".buttonload_booking").css("display", "none"), $(this).parent().find(".submit-order").css("display", "block")
    }))
})), $(document).ready((function () {
    if (/Mobi/.test(navigator.userAgent)) {
        var e = $(this).attr("data-target");
        $(".showPopup").attr("data-target", e)
    } else $(".showPopup").attr("data-target", "")
})), $((function () {
    setCustomValidationMessages()
})), $(document).ready((function () {
    function sortpricelowtoheight() {
        $(".wrapBoxProduct").each((function () {
            var e, t = $(this).find(".price").length;
            for (e = 0; e < t - 1; e++) for (var a = 0; a < t - e - 1; a++) if (!(Number($(this).find(".price")[a + 1].getAttribute("value")) > Number($(this).find(".price")[a].getAttribute("value")))) {
                var i = $(this).find(".price")[a].parentElement.parentElement.parentElement.parentElement.cloneNode(!0);
                $(this).find(".price")[a].parentElement.parentElement.parentElement.parentElement.replaceWith($(this).find(".price")[a + 1].parentElement.parentElement.parentElement.parentElement.cloneNode(!0)), $(this).find(".price")[a + 1].parentElement.parentElement.parentElement.parentElement.replaceWith(i)
            }
        }))
    }

    function sortpriceheighttolow() {
        $(".wrapBoxProduct").each((function () {
            var e, t = $(this).find(".price").length;
            for (e = 0; e < t - 1; e++) for (var a = 0; a < t - e - 1; a++) if (!(Number($(this).find(".price")[a + 1].getAttribute("value")) < Number($(this).find(".price")[a].getAttribute("value")))) {
                var i = $(this).find(".price")[a].parentElement.parentElement.parentElement.parentElement.cloneNode(!0);
                $(this).find(".price")[a].parentElement.parentElement.parentElement.parentElement.replaceWith($(this).find(".price")[a + 1].parentElement.parentElement.parentElement.parentElement.cloneNode(!0)), $(this).find(".price")[a + 1].parentElement.parentElement.parentElement.parentElement.replaceWith(i)
            }
        }))
    }

    function sort_default() {
        window.location.href = window.location.href
    }

    $(".sort-product").click((function () {
        var method = $(this).attr("data-funtion-name");
        window.settings = {functionName: method}, eval(settings.functionName + "()")
    }))
})), $(document).ready((function () {
    function e() {
        var e, t, a, i = "<ul>";
        $(".chauvh-detail h3, .chauvh-detail h2 ").each((function (o, n) {
            t = $(this), a = t.text().trim();
            var r = a;
            t.attr("id", r), "H3" == n.tagName ? e = "<li class='sub_data'><a href='" + location.origin + location.pathname + "#" + r + "'>" + a + "</a></li>" : "H2" == n.tagName && (e = "<li  class='data'><a href='" + location.origin + location.pathname + "#" + r + "'>" + a + "</a></li>"), i += e
        })), null == e ? i = "" : i += "</ul>", $("#bookmark-list").prepend(i)
    }

    function t() {
        $(".tableOfContent .clickToggle").click((function () {
            var e = $(this).parent().parent();
            e.hasClass("appearContent") ? e.removeClass("appearContent") : e.addClass("appearContent")
        }))
    }

    e(), t()
}));
